require("./styles/index.scss");

const images = ["1.png", "2.png", "3.png", "4.png"].map(img =>
  require(`./assets/illustrations/${img}`)
);
const illustration = document.getElementById("illustration");
let index = 0;

setInterval(() => {
  const nextIndex = ++index % images.length;
  illustration.src = images[nextIndex];
}, 3000);
